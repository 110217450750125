* {
    margin: 0;
    padding: 0;
}
.Login {
    display: grid;
    /* grid-template-columns: 0.4fr 1fr; */
    grid-template-columns: 35% 65%;
}
.row:nth-child(odd) {
    background-color: lightgrey;
}
.Login > img {
    width: 100%;
    padding-bottom: 3rem;
    height: 100%;
    background: #54215d;
    /* min-height: 300px; */
    min-height: 100vh;
}
.business-user-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.add-item {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    width: 100%;
}
.add-item .upload-icon {
    color: #0e9f6e;
    font-size: 2rem;
}
.update-business-btn {
    width: fit-content;
    align-self: center;
    justify-self: center;
    background-color: #6865f0;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    margin-right: 17rem;
}
.add-item h5 {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
.add-item p {
    color: #9e9e9e;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
.item-image-upload label,
.warehouse-multi-select label,
.item-tag-input label {
    font-family: sans-serif;
    color: #24262d;
    font-size: 14px;
}
.item-image-upload {
    display: grid;
    gap: 0.5rem;
}
.upload-image {
    border: 2px dashed #d5d6d7;
    width: 70%;
    height: 9rem;
    display: grid;
    align-items: center;
    cursor: pointer;
}
.login-form {
    display: flex;
    flex-direction: column;
    gap: 8rem;
    margin: 3rem 0 0 3rem;
    background: url("../Assets/images/login/loginright.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.login-form .header h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 67px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #000002;
}
.login-form .header h3 span {
    color: #f55700;
}

.dashboardContainer {
    background-color: white;
    margin-right: 5rem;
    border-radius: 10px;
    padding: 1rem;
}
.login-form .header p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 1.9rem;
    line-height: 37px;

    letter-spacing: 0.01em;

    color: #565656;
}
.wrapper {
    justify-self: center;
    align-self: center;
    width: 80%;
    padding: 2rem 0;
    border-radius: 15px;
    background: #fef7e7;
}
.dashboard-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboard-header-wrapper select {
    font-size: 1.1rem;
    height: 2.1rem;
}
.login-form form {
    display: grid;
    gap: 1rem;
}
.login-form form .form-control {
    position: relative;
    margin-left: 3rem;
}
.login-form form .form-control input {
    border: none;
    outline: none;
    width: 80%;
    height: 3rem;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 32px;
    /* identical to box height */
    border-radius: 1rem;
    letter-spacing: 0.01em;
    color: #565656;
    text-indent: 3rem;
}
.form-control img {
    position: absolute;
    left: 12px;
    width: 20px;
    height: 20px;
    top: 13px;
}
.login-form button {
    cursor: pointer;
    margin-top: 1rem;
    width: 75%;
    margin-left: 3rem;
    font-size: 1.6rem;
    padding: 0.4rem 1rem;
    background: #54215d;
    border-radius: 20px;
    border: none;
    outline: none;
    color: white;
}
.business-user-header button {
    cursor: pointer;
    font-size: 1rem;
    padding: 0.4rem 1rem;
    background: #54215d;
    border-radius: 5px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
}
.paid-button {
    color: #6865f0;
    padding: 0.5em 1rem;
    border: 1px solid #6865f0;
    background-color: white;
    cursor: pointer;
}
.paid-button:hover {
    background-color: #6865f0;
    color: white;
}
@media screen and (max-width: 1100px) {
    .Login {
        grid-template-columns: 1fr;
        align-items: center;
    }
    .Login > img {
        display: none;
    }
    .login-form {
        gap: 5rem;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1rem;
    }
    .login-form form {
        margin-left: 2rem;
    }
    .login-form form .form-control {
        margin-left: 0;
    }

    .login-form form button,
    .login-form form .form-control input {
        margin-left: 0;
        width: 90%;
    }
    .wrapper {
        align-self: unset;
        justify-self: unset;
        width: 100%;
    }
}
@media screen and (min-width: 1500px) {
    .wrapper {
        margin: 2rem;
        width: 90%;
        /* display: grid; */
    }
    .wrapper form {
        gap: 2rem;
        /* margin-top: 3rem; */
    }
}

/* -----------navbar----------------- */
.navbar-main {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    background: #6865f0;
}
.navbar-main .logo {
    align-self: center;
}
.navbar-main .logo img {
    width: 220px;
    height: 220px;
}
.nav-links {
    display: grid;
    gap: 1rem;
    padding-bottom: 1rem;
}
.nav-links a,
.nav-links button {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 31px;
    text-decoration: none;
    letter-spacing: 0.08rem;
    color: #ffffff;
    display: flex;
    gap: 0.8rem;
    align-items: center;
    padding-left: 1.4rem;
}
.nav-links .navlinkactive {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-right: 10px solid #ffffff;
    /* color: white; */
    background-color: rgba(255, 255, 255, 0.2);
}
.nav-links .navlinkactive > p {
    opacity: 1;
}
.nav-links button {
    border: none;
    background-color: inherit;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
}
@media screen and (max-width: 1000px) {
    .navbar-main .logo img {
        height: 100px;
        width: 100px;
    }
}
@media screen and (max-width: 920px) {
    .nav-links a p,
    .navbar-main button p {
        display: none;
    }
    .nav-links a,
    .navbar-main button {
        margin: 0;
        padding-left: 0.3rem;
    }
    .nav-links {
        /* justify-items: center; */
        gap: 2rem;
    }

    .navbar-main .logo img {
        visibility: hidden;
        width: 40px;
        height: 130px;
    }
}
/* ---main-top-nav--------- */

.main-top-nav {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1rem;
    justify-content: right;
    background-color: rgba(180, 172, 172, 0.8);
}
.main-top-nav p {
    font-size: 1.3rem;
    font-weight: 450;
    font-family: Arial, Helvetica, sans-serif;
}
/* --------------dashboard------- */
.ui {
    display: grid;
    grid-template-columns: 22% 78%;
    height: 100%;
    min-height: 100vh;
}
.ui-leftside {
    background: #6865f0;
    height: 100%;
}
.ui-leftside .navbar {
    min-height: 100vh;
    height: 100%;
}
.ui-rightside {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    background-color: #e5e5e5;
}
.ui-wrapper {
    margin-top: 1rem;
    margin-left: 3rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
@media screen and (max-width: 920px) {
    .ui {
        grid-template-columns: 9% 91%;
    }
    .ui-leftside {
        max-width: 100px;
    }
}
@media screen and (max-width: 600px) {
    .update-business-btn {
        margin: 0;
    }
}
/* --------user list topnav---------------- */

.topNav-Links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 160px));
    align-items: center;

    gap: 2rem;
}
.topNav-Links a {
    text-align: center;
    text-decoration: none;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 16px;
}
.topNav-Links .topnavactive {
    color: #ffffff;
    background: #6865f0;
}
.topNav-Links .topnavinactive {
    background-color: white;
    color: black;
}
@media screen and (max-width: 900px) {
    .ui-wrapper {
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }
    .topNav-Links {
        grid-template-columns: repeat(auto-fit, minmax(55px, 90px));
        gap: 0.8rem;
    }
    .topNav-Links a {
        text-align: center;
        padding: 0rem;
        font-size: 0.7rem;
    }
}
/* ----------user list ---------------- */

.user-details:hover,
.transactions-row:hover,
.referral-row:hover {
    background-color: rgba(209, 199, 199, 0.1);
    cursor: pointer;
}
.filter-wrapper {
    display: grid;
    grid-template-columns: 60% 40%;
}
.filter {
    margin-right: 3rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    width: fit-content;
    justify-self: right;
}
.filter select {
    outline: none;
    border-radius: 10px;
    font-size: 1.3rem;
    /* width: 55%; */
    width: 23rem;
    border: none;
    overflow: none;
    height: 3rem;
    border: 1px solid #6865f0;
    text-indent: 1rem;
    padding: 0 1.5rem;
    border: 1px solid #333;
    font-family: inherit;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}
.filter > button {
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1.1rem;
    color: white;
    background-color: #6865f0;
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
}
@media screen and (max-width: 1250px) {
    .filter-wrapper {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .search-data input {
        width: 100%;
    }
    .filter {
        justify-self: left;
    }
    .filter select {
        width: 100%;
    }
}
/* -------user details----------- */
.User-name {
    display: grid;
    gap: 1rem;
}
.User-name > p {
    font-size: 2rem;
    text-align: center;
    text-decoration: none;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    box-sizing: border-box;
    justify-self: center;
    margin-bottom: 1rem;
    margin-right: 3rem;
}
.User-name .back-button {
    width: fit-content;

    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 1rem;
    border-radius: 16px;
    border: none;
    cursor: pointer;
}
.search-data {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.search-data .search-icon {
    position: absolute;
    top: 0;
    left: 8px;
    top: 12px;
}
.search-data input {
    outline: none;
    border-radius: 10px;
    font-size: 1.3rem;
    width: 55%;
    border: none;
    overflow: none;
    height: 3rem;
    border: 1px solid #6865f0;
    text-indent: 2.5rem;
}
.search-data button {
    width: fit-content;
    height: fit-content;
    font-size: 1.1rem;
    color: white;
    background-color: #6865f0;
    border-radius: 10px;
    outline: none;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
}
.userdetails-wrapper {
    background-color: white;
    margin-right: 3rem;
    border-radius: 10px;
    padding: 1rem 2rem 3rem 2rem;
}
.user-data-container {
    width: 75%;
    display: grid;
    gap: 1rem;
}
.user-data {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.user-data-address {
    /* justify-self: flex-end; */
    /* align-self: flex-end; */
    text-align: right;
    width: 350px;
    word-wrap: break-word;
}
.user-data h3,
.detail-header {
    font-size: 1.7rem;
    font-weight: 500;
}
.user-data h4 {
    font-size: 1.2rem;
    font-weight: 500;
}
.user-data p {
    align-self: flex-start;
    font-size: 1.3rem;
}
.user-data img {
    width: 100px;
}
@media screen and (max-width: 1200px) {
    .user-data-container {
        width: 100%;
    }
}
@media screen and (max-width: 800px) {
    .search-data input {
        width: 80%;
    }
    .userdetails-wrapper {
        padding: 1rem;
        margin-right: 0;
    }
    .User-name > p {
        margin-right: 0;
        font-size: 1.3rem;
    }
    .user-data span {
        display: none;
    }
    .user-data img {
        border-radius: 50%;
    }
    .user-data {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 1rem;
    }
    .detail-header {
        text-align: center;
    }
    .user-data p {
        font-size: 1.1rem;
    }
    .user-data-address {
        text-align: center;
        width: 300px;
    }
}
/* -----userlist - dashboard -------------- */
.DashBoardWrapper {
    background-color: white;
    margin-right: 5rem;
    border-radius: 10px;
    padding: 1rem 2rem 3rem 2rem;
    display: grid;
    gap: 1rem;
}
.DashBoardWrapper h3 {
    font-weight: 500;
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(48, 45, 45);
}
.dashboard-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 2.5rem;
    margin-right: 1rem;
    text-align: center;
}

.dashboard-item {
    background-color: rgba(104, 101, 240, 0.4);
    width: fit-content;
    display: grid;
    padding: 1rem;
    min-height: 2.5rem;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.dashboard-item h3,
.dashboard-item .count {
    font-size: 1.3rem;
    font-weight: 550;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
}
.dashboard-item .count {
    font-size: 2.2rem;
}
@media screen and (max-width: 767px) {
    .DashBoardWrapper {
        margin-right: 1rem;
        padding: 0.5rem;
    }
    .dashboard-items {
        padding-bottom: 2rem;
    }
    .dashboard-item {
        text-align: center;
        width: 100%;
        padding: 1rem 0.3rem;
        margin: 0;
    }
    .dashboard-item h3 {
        font-size: 1.3rem;
    }
}
/* -----------Transactions------------ */

.dateinputs {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.dateinputs input,
.dateinputs select {
    padding: 0.5rem;
    color: #6865f0;
    border-radius: 10px;
    border: 1px solid #6865f0;
    font-weight: 500;
    font-size: 1rem;
}
.dateinputs input::-webkit-calendar-picker-indicator {
    filter: invert(33%) sepia(90%) saturate(925%) hue-rotate(215deg)
        brightness(106%) contrast(89%);
}
.dateinputs button {
    cursor: pointer;
    background-color: #6865f0;
    border-radius: 1.5rem;
    width: fit-content;
    height: fit-content;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: white;
    outline: none;
    border: none;
}
.add-refer-byCode,
.edit-refer-code {
    display: flex;
}
.edit-refer-code {
    gap: 1rem;
}
.add-refer-byCode input {
    text-indent: 0.5rem;
}
.add-refer-byCode button,
.edit-refer-code button {
    cursor: pointer;
    background-color: #6865f0;
    /* border-radius: 1.5rem; */
    width: fit-content;
    height: fit-content;
    font-size: 1rem;
    padding: 0.3rem 1rem;
    color: white;
    outline: none;
    border: none;
}
.pending-span {
    background: yellow;
    color: #5b5b5a;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.completed-span {
    background: #1fb770;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.failed-span {
    background: red;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}

.reversed-span {
    background: orange;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
}
/* ---------------referral-------------- */

.ui-wrapper .Table {
    background-color: white;
    overflow: scroll;
    margin-right: 3rem;
    border-radius: 10px;
    padding: 2rem;
}
.ui-wrapper .Table h3 {
    font-weight: 500;
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(48, 45, 45);
    margin-bottom: 1rem;
}
.ui-wrapper .Table table {
    border-collapse: collapse;
    width: 100%;
}
.ui-wrapper .Table table thead {
    border-bottom: 1px solid grey;
}
.ui-wrapper .Table table thead tr th {
    text-align: center;
}
.ui-wrapper .Table table tr td {
    font-family: "Rubik";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 31px;
    color: #252523;
    text-align: center;
    padding: 1rem 0;

    letter-spacing: 0.03rem;
}
.ui-wrapper .Table table tbody tr td {
    font-weight: 400;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.ui-wrapper .Table::-webkit-scrollbar {
    display: none;
}
@media screen and (max-width: 1100px) {
    .ui-wrapper .Table table tr td,
    .ui-wrapper .Table table thead tr th {
        padding: 1rem;
    }
}
@media screen and (max-width: 787px) {
    .ui-wrapper .Table {
        margin-right: 0.4rem;
    }
}
/* ------settings------------ */
.settings {
    background-color: white;
    margin-right: 5rem;
    border-radius: 10px;
    padding: 1rem 2rem 3rem 2rem;
    display: grid;
    gap: 1rem;
}
.settting-detail,
.file_upload {
    display: grid;
    gap: 1rem;
}
.settting-detail textarea {
    width: 40%;
    font-size: 1.3rem;
    padding-left: 1rem;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    resize: none;
}
.settting-detail label,
.settting-detail-percentage label,
.file_upload label {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}
.settting-detail-percentage,
.file_upload {
    display: grid;
    grid-template-columns: 35% 1fr;
    align-items: center;
    gap: 2rem;
}
.file_upload {
    align-items: baseline;
}
.file_upload div {
    display: grid;
    gap: 1rem;
}
.file_upload img {
    width: 13rem;
}
.settting-detail-percentage input {
    height: 2.5rem;
    font-size: 1.3rem;
    text-indent: 1rem;
    width: 60%;
    /* width: 6rem; */
}
.settings-btns {
    margin: 1.5rem 4rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.settings-btns button {
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 31px;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 16px;
    border: none;
}
.settings-btns .active-btn {
    color: #ffffff;
    background: #6865f0;
}
.radio-button-wrapper {
    display: flex;
    gap: 3rem;
    align-items: center;
}
.radio-buttons {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.radio-buttons input {
    -ms-transform: scale(1.4); /* IE 9 */
    -webkit-transform: scale(1.4); /* Chrome, Safari, Opera */
    transform: scale(1.4);
}
.settting-detail > input,
.radio-button-wrapper > input {
    height: 2.4rem;
    width: 12rem;
    outline: none;
    border: 1.5px solid #6865f0;
    font-size: 1.5rem;
    font-weight: 400;
    text-indent: 0.5rem;
}
.settting-detail > input::-webkit-outer-spin-button,
.radio-button-wrapper > input::-webkit-outer-spin-button,
.settting-detail > input::-webkit-inner-spin-button,
.radio-button-wrapper > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.radio-button-wrapper > input,
.settting-detail > input[type="number"] {
    -moz-appearance: textfield;
}
.settings > button {
    align-self: center;
    justify-self: center;
    background-color: #6865f0;
    border: none;
    outline: none;
    font-size: 1.3rem;
    border-radius: 10px;
    padding: 0.5rem 3rem;
    color: white;
    cursor: pointer;
    margin-top: 1rem;
}
.channels {
    width: 100%;
    display: grid;
    gap: 1rem;
}
.channels h3 {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}
.channels h3 button {
    border: none;
    padding: 0.3rem;
    border-radius: 5px;
    color: white;
    background-color: green;
    cursor: pointer;
}
.channels table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    border: 1px solid #ccc;
}
.channels table input {
    width: 100%;
    height: 2.5rem;
    border: none;
    outline: none;
}
.channels table th,
.channels table td {
    border: 1px solid #ccc;
    padding: 0.5rem;
}
.channels table td button {
    color: white;
    background-color: red;
    padding: 0.3rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
@media screen and (max-width: 900px) {
    .radio-button-wrapper {
        gap: 1rem;
    }
    .payoutcharges {
        display: grid;
        align-items: left;
        justify-items: left;
    }
    .settings {
        margin-right: 1rem;
    }
    .settting-detail-percentage {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 90%;
    }
    .settting-detail textarea {
        width: 85%;
    }
}
/* -------admin transactions--------- */

.controlButtons {
    min-width: 5rem;
    height: 2rem;
    font-size: 1rem;
}

/* -------------modal----------------- */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.modal-content {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    max-width: 700px;
    width: 100%;
    height: 98%;
    overflow: scroll;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}
.modal-content::-webkit-scrollbar {
    display: none;
}
.transactionDetails-wrapper {
    display: grid;
    margin: 0.5rem 0;
    align-items: center;
}
.row {
    margin: 0 1rem;
    display: grid;
    grid-template-columns: 1fr 0.06fr 1fr;
    padding: 0.6rem;
}
.colored-row {
    background-color: lightgrey;
}
.row h3 {
    font-weight: 500;
}
.row p span {
    margin-right: 0.4rem;

    font-weight: bold;
}
.row a {
    background: #1fb770;
    color: white;
    padding: 6px 10px;
    border-radius: 15px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 700;
}
@media screen and (max-width: 800px) {
    .modal-content {
        gap: 1rem;
        margin: 1rem 0;
        height: 600px;
        overflow: scroll;
    }
    .transactionDetails-wrapper {
        overflow: scroll;
    }
    .row {
        gap: 1rem;
    }
}
/* --------------business info--------- */
.business-info {
    margin-right: 3rem;
    border-radius: 10px;
    padding: 1rem 0rem 3rem 2rem;
    background-color: white;
    display: grid;
}
.business-approve {
    display: flex;
    justify-content: space-between;
}
.business-approve p,
.business-approve button {
    margin-right: 1rem;
}
.business-approve button {
    border: none;
    background-color: #6865f0;
    color: white;
    padding: 0.6rem 1rem;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
}
.business-approve p {
    color: white;
    padding: 0.6rem 1rem;
    border-radius: 10px;
    font-size: 1rem;
    background-color: green;
}
.business-approve > h3 {
    font-size: 1.5rem;
    font-weight: 500;
}

.business-info p {
    font-size: 1.2rem;
}
.info {
    margin: 0 1rem;
    display: grid;
    grid-template-columns: 1fr 0.06fr 1fr;
    padding: 0.6rem;
}
.info-pictures {
    display: grid;
    gap: 0.5rem;
}
.info h4,
.info-pictures h4 {
    font-weight: 500;
    font-size: 1.3rem;
}
.info-pictures,
.business-info a {
    margin: 0 1rem;
    padding: 0.6rem;
}
/* ----------------css spinner---------- */
.spin-wrapper {
    height: 100vh;
    display: grid;
    place-items: center;
}
.spinner {
    width: 50px;
    height: 50px;

    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 3px dashed #f1d1dd;
    border-top-color: transparent;
    border-bottom-color: transparent;
    animation: spin 1s infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg) scale(1.2);
    }
    100% {
        transform: rotate(360deg);
    }
}
.settings-transactions {
    margin-top: 0rem;
    margin-left: 0rem;
}
.virtual-money {
    background-color: white;
    display: grid;
    gap: 1rem;
    margin-right: 3rem;
    border-radius: 10px;
    padding: 2rem;
}
.virtual-money .form-control {
    display: grid;
    grid-template-columns: 25% 1fr;
    gap: 1rem;
}
.virtual-money .form-control input,
.virtual-money select {
    height: 2.5rem;
    font-size: 1.3rem;
    text-indent: 1rem;
    width: 60%;
}
.virtual-money select {
    font-size: 1rem;
    width: 61%;
}
.virtual-money .form-control label {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}
.money-submit,
.load-btn {
    background-color: #6865f0;
    color: white;
    padding: 0.5rem 1rem;
    outline: none;
    font-size: 1.1rem;
    cursor: pointer;
    border: none;
    margin: 1rem 6rem 0 0;
    width: fit-content;
    align-items: center;
    justify-self: center;
    border-radius: 6px;
}
.load-btn {
    background-color: inherit;
    border: none;
}
.virtual-money p {
    color: red;
    margin-top: 0.3rem;
}
@media screen and (max-width: 860px) {
    .virtual-money .form-control {
        grid-template-columns: 1fr;
    }
    .virtual-money .form-control input,
    .virtual-money select {
        width: 90%;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
