.modal-confirm {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.modal-content-confirm {
    display: grid;
    justify-items: center;
    gap: 2rem;
    opacity: 1;
    max-width: 700px;
    width: 100%;
    padding: 2rem 0;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}
.modal-content-confirm .close-btn {
    position: absolute;
    right: 3px;
    border-top-right-radius: 14px;
    top: -32px;
    border: none;
    color: #6865f0;
    background-color: white;
}
.modal-content-confirm p {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 53px;
    text-align: center;

    color: #1f0f34;
}
.control-btns {
    display: flex;
    gap: 1rem;
}
.control-btns button {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    text-align: center;
    padding: 0.6rem 7rem;
    border-radius: 14px;
    cursor: pointer;
}
.control-btns .cancel-btn {
    background: #ffffff;
    color: #6865f0;
    border: 1px solid #6865f0;
}
.control-btns .delete-btn:active {
    background-color: #6865f0;
}
.control-btns .cancel-btn:active {
    background-color: #f5f5f5;
    /* border: none; */
}
.control-btns .delete-btn {
    border: none;
    background: #6865f0;
    color: #ffffff;
}
.modal-content-payment {
    display: grid;
    justify-items: center;
    gap: 1rem;
    opacity: 1;
    max-width: 500px;
    width: 100%;
    padding: 0.5rem 0;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}
.modal-content-payment form {
    display: grid;
    gap: 1rem;
    width: 90%;
}
.modal-content-payment h3 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    /* text-transform: uppercase; */
    line-height: 31px;
    box-sizing: border-box;
    justify-self: center;
}
.modal-content-payment input {
    border: 1px solid #ccc;
    height: 3rem;
    text-indent: 0.5rem;
    outline: none;
    cursor: pointer;
    border-radius: 10px;
    cursor: pointer;
}
.modal-content-payment textarea {
    outline: none;
    resize: none;
    border-radius: 10px;
    padding: 0.5rem;
    border: 1px solid #ccc;
}
.modal_paymnet_formcontrol {
    display: grid;
    gap: 0.6rem;
}
.modal_paymnet_formcontrol div {
    display: grid;
    gap: 0.5rem;
}
.modal_paymnet_formcontrol div span {
    color: red;
}
.modal-content-payment input[type="submit"] {
    background-color: #6865f0;
    color: white;
    text-indent: 0;
    width: fit-content;
    justify-self: center;
    padding: 0 2rem;
}
@media screen and (max-width: 790px) {
    .control-btns button {
        padding: 0.5rem 4rem;
    }
}
@media screen and (max-width: 800px) {
    .modal-content-confirm,
    .modal-content-payment {
        gap: 1rem;
        margin: 1rem;
        height: fit-content;
        padding: 0.1rem;
    }
    .modal-content-confirm .close-btn {
        top: -15px;
    }
    .modal-content-confirm p {
        font-size: 18px;
    }
    .control-btns button {
        font-size: 14px;
        padding: 0.5rem 2rem;
    }
}
